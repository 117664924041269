import { spawn } from 'redux-saga/effects'

// Sagas
import authSaga from "./auth-sage";
import userSaga from "./user-saga";
import billingSaga from "./billing-saga";
import configSaga from './configurator-saga';
import commonSaga from './common-sage';
import messageSaga from './message-sage';
import paymentMethodSaga from './payment-method-saga';
import preferenceSaga from './preference-sage'
import giftSaga from './gift-sage'
import challengeSaga from "./challenge-saga";
import achievementSaga from "./achievement-saga";
import storiesSaga from "./stories-sage";

// Export the root saga
export default function* rootSaga() {
    yield spawn(authSaga)
    yield spawn(userSaga)
    yield spawn(billingSaga)
    yield spawn(configSaga)
    yield spawn(commonSaga)
    yield spawn(messageSaga)
    yield spawn(paymentMethodSaga)
    yield spawn(preferenceSaga)
    yield spawn(giftSaga)
    yield spawn(challengeSaga)
    yield spawn(achievementSaga)
    yield spawn(storiesSaga)
}
