import {stringToBoolean} from "./validation";

export const returnUrl = process.env.REACT_APP_BASE_URL+'/account'
export const testHomeworks = process.env.REACT_APP_TEST_HOMEWORKS
export const paymentApiUrl = process.env.REACT_APP_PAYMENT_URL+'/api/v1'
export const homeworkApiUrl = process.env.REACT_APP_HOMEWORK_API_URL
export const accountApiUrl = process.env.REACT_APP_ACCOUNT_API_URL
export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLICK_KEY
export const cloudpaymentsPublicKey = process.env.REACT_APP_CLOUDPAYMENTS_PUBLIC_KEY
export const billingPaths = [
    'account',
    'subscription',
    'billing',
    'challenges'
]
export const privatePaths = [
    'sprint',
    'challenges',
    'account',
    'billing',
    'homework',
    'day',
    'sprint'
]

export const authPaths = [
    '/login',
    '/logout',
    '/verify-email',
]

export const stripeCurrencies = ['aud', 'eur', 'usd']

export const isProduction = stringToBoolean(process.env.REACT_APP_TEST) === false
