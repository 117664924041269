import React from 'react'
import 'react-edit-text/dist/index.css';
import PropTypes from "prop-types";

import {connect} from "react-redux";
import ReactPlayer from 'react-player/lazy'
import {
    FiPause,
    FiPlay,
} from "react-icons/fi";

import {MdReplay} from "react-icons/md";

class Player extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playing: false,
            pauseButton: false,
            isOverTime: false
        };

    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if(this.player && prevProps.startTime !== this.props.startTime){
    //         this.player.seekTo(this.props.startTime, "seconds")
    //     }
    // }

    // initVideo = () => {
    //     this.player.seekTo(this.props.startTime, "seconds")
    // }

    handleProgress = state => {

        if (this.state.isOverTime === false && this.props.endTime === parseInt(state.playedSeconds) && this.state.playing){
            this.setState({
                playing: false,
                isOverTime: true
            })
        }
    }

    handleStartTime = () => {
        this.player.seekTo(this.props.startTime, "seconds")
        this.setState({
            playing: true,
            isOverTime: false
        })
    }

    handlePlay = () => {
        this.setState({
            playing: !this.state.playing,
        })
    }

    ref = player => {
        this.player = player
    }

    render() {
        let canPlay = ReactPlayer.canPlay(this.props.videoLink)

        return (
            <React.Fragment>
                <>
                    { canPlay ? (
                        <>
                            <div className="w-full aspect-video">
                                <div className="player-wrapper mb-5">
                                    <ReactPlayer
                                        ref={this.ref}
                                        width="100%"
                                        height="100%"
                                        className={"react-player"}
                                        // onReady={this.initVideo}
                                        onProgress={this.handleProgress}
                                        onStart={()=>{
                                            if(!this.state.pauseButton) {
                                                this.setState({
                                                    pauseButton: true,
                                                    playing: true
                                                })
                                            }
                                        }}
                                        onPlay={()=>{
                                            if(!this.state.pauseButton) {
                                                this.setState({
                                                    pauseButton: true,
                                                    playing: true
                                                })
                                            }
                                        }}
                                        onPause={()=>{
                                            if(this.state.pauseButton){
                                                this.setState({
                                                    pauseButton: false,
                                                    playing: false
                                                })
                                            }
                                        }}
                                        onEnded={()=>{
                                            if(this.state.pauseButton) {
                                                this.setState({
                                                    pauseButton: false,
                                                    playing: false
                                                })
                                            }
                                        }}
                                        playing={this.state.playing}
                                        url={this.props.videoLink}
                                        onError={e => console.log('onError', e)}
                                        config={{
                                            youtube: {
                                                playerVars: {
                                                    showinfo: 0,
                                                    cc_load_policy: 0,
                                                    start: this.props.startTime,
                                                    // end: this.props.endTime
                                                }
                                            },
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row justify-between">
                                    <button
                                        onClick={this.handlePlay}
                                        className={`btn btn-sm rounded-full shadow-brand bg-white border-white border-2 focus:border-white hover:border-white focus:bg-white hover:bg-white`}
                                    >
                                        {this.state.pauseButton ?
                                            <FiPause  className="w-6 h-6 stroke-current text-primary" /> :
                                            <FiPlay className="w-6 h-6 stroke-current text-primary"/>
                                        }
                                    </button>
                                    <button
                                        onClick={this.handleStartTime}
                                        className="btn btn-sm shadow-brand bg-white rounded-full btn-wide border-white border-2 focus:border-white hover:border-white focus:bg-white hover:bg-white"
                                    >
                                        <MdReplay className="w-6 h-6 stroke-current mr-1 text-primary"/>
                                        <span className="text-primary">Play again</span>
                                    </button>

                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="w-full aspect-video self-center">
                            <svg width="100%"
                                 height="100%"
                                 style={{ borderRadius: '0.75rem', overflow: 'hidden' }}
                                 className="react-player"
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 1920 1080"
                            >
                                <rect fill={"#cbd5e0"} className="cls-1" width="1920" height="1080"/>
                            </svg>
                        </div>
                    ) }
                </>


            </React.Fragment>
        );
    }
}

Player.propTypes = {
    loading: PropTypes.bool,
    videoLink: PropTypes.string,
    startTime: PropTypes.number,
    endTime: PropTypes.number,
}

const mapStateToProps = (state) => ({
    loading: state.config.loading,
    videoLink: state.config.videoLink,
    startTime: state.config.startTime,
    endTime: state.config.endTime,
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Player)
