import React, {useEffect, useState} from 'react'

import {connect} from "react-redux";
import PropTypes from "prop-types";
import Grid from "./grid";
import Stories from "./stories";
import {SHOW_THANKS_MODAL} from "../../redux/actions/common-actions";
import Cookies from "js-cookie";
import {GET_STORIES_BY_COHORTS, SHOW_STORIES} from "../../redux/actions/stories-actions";


const Welcome = ({
                     storiesIsOpen,
                     showThanksModal,
                     mainContainerRef,
                     userInfo,
                     getStoriesByCohorts,
                     showStories,
                     series,
}) => {

    const [maxHeight, setMaxHeight] = useState(0)
    const [maxWidth, setMaxWidth] = useState(0)

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            showThanksModal();
        }

    }, []);

    useEffect(() => {
        const calculateHeight = () => {

            if(mainContainerRef.current){
                const calculatedHeight = mainContainerRef.current.offsetHeight; // - footerHeight;
                const calculatedWidth =  mainContainerRef.current.offsetWidth;
                setMaxHeight(calculatedHeight);
                setMaxWidth(calculatedWidth);
            }
        };

        calculateHeight();

        // Обработчик изменения размеров окна
        const handleResize = () => {
            calculateHeight();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(()=>{
        if(userInfo){
            getStoriesByCohorts(userInfo.cohorts.join())
        }
    }, [userInfo])

    useEffect(() => {
        if(series.length > 0 && userInfo?.isNewStudent){
            const sw = Cookies.get('onboarding')
            if (sw === undefined) {
                showStories()
                Cookies.set('onboarding', true, { expires: 365, path: '' })
            }
        }
    }, [series]);

    return (
        <div className={"relative bg-white w-full"}>
            <Grid/>
            <dialog
                className={`flex flex-row justify-center modal modal-top h-screen ${storiesIsOpen ? 'modal-open' : null}`}
            >
                <form method={"dialog"}
                      style={{maxHeight: maxHeight, height: maxHeight, maxWidth: maxWidth, width: maxWidth}}
                      className={`flex flex-col justify-between h-full p-0 modal-box rounded-none relative bg-white w-screen xs:max-w-laptop overflow-hidden`}
                >
                    <Stories
                        maxHeight={maxHeight}
                        maxWidth={maxWidth}
                    />
                </form>
            </dialog>
        </div>
    )
}

Welcome.propTypes = {
    storiesIsOpen: PropTypes.bool,
    showThanksModal: PropTypes.func,
    mainContainerRef: PropTypes.any,
    loadingSeries: PropTypes.bool,
    showStories: PropTypes.func,
    getStoriesByCohorts: PropTypes.func,
    series: PropTypes.array,
    userInfo: PropTypes.object
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    storiesIsOpen: state.stories.storiesIsOpen,
    loadingSeries: state.stories.loadingSeries,
    series: state.stories.series,
})

const mapDispatchToProps = (dispatch) => ({
    showThanksModal: (message) => dispatch({type: SHOW_THANKS_MODAL, payload: message}),
    showStories: () => dispatch({type: SHOW_STORIES}),
    getStoriesByCohorts: (cohort) => dispatch({type: GET_STORIES_BY_COHORTS, payload: cohort})
})

export default connect(mapStateToProps, mapDispatchToProps)(Welcome)


