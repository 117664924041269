import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {withUseFormHook} from "../../helpers/with-form-hook";
import {UPDATE_STUDENT_PROFILE} from "../../redux/actions/user-actions";
import {getPaymentRedLine} from "../../helpers/validation";
import PutOnHoldModal from "./components/PutOnHoldModal";
import Profile from "./tabs/Profile";
import AccountHeader from "./components/AccountHeader";
import History from "./tabs/history";
import Loading from "../components/Loading";
import GiftCards from "./tabs/gifts";

const Account = ({
                     userInfo,
                     updateStudentProfile,
                     updateProfileLoading,
                     currentSprint,
                     displayMode
}) => {

    const hasSubscription = userInfo?.userPaymentInfo?.hasSubscription;

    const paymentRedLine = getPaymentRedLine()
    const paymentRedLineLocalFormat = paymentRedLine.local().format('DD-MM-YYYY HH:mm')
    const sprintMonday = paymentRedLine.isoWeekday(1).format('DD-MM-YYYY')
    const sprintFriday = paymentRedLine.add(4, 'days').format('DD-MM-YYYY')

    const [tab, setTab] = useState('profile')
    const [isSaveButton, setIsSaveButton] = useState(false)
    const [openPutOnHoldModal, setOpenPutOnHoldModalState] = useState(false)

    const [userUid, setUseUid] = useState(userInfo?.uid)
    const [country, setCountry] = useState(userInfo?.profile?.country);
    const [email, setEmail] = useState(userInfo?.profile?.email)
    const [currency, setCurrency] = useState(userInfo?.profile?.currency)
    const [instagram, setInstagram] = useState(userInfo?.profile?.instagram)
    const [isActiveProfile, setIsActiveProfile] = useState(userInfo?.profile?.status === 'active');

    useEffect(()=> {
        if(userInfo){
            setUseUid(userInfo.uid)
            setCountry(userInfo.profile.country)
            setEmail(userInfo.profile.email)
            setCurrency(userInfo.profile.currency)
            setInstagram(userInfo.profile.instagram)
            setIsActiveProfile(userInfo.profile.status === 'active')
        }
    }, [userInfo])

    const updateSwitchState = () => {
        setIsActiveProfile(isActiveProfile)
        setIsSaveButton(false)
        updateStudentProfile({
            user: userInfo.uid,
            status: !isActiveProfile ? 'active' : 'freeze'
        })
    }

    return (
        <React.Fragment>
            {userInfo ? (
                <>
                    <div
                        className="flex flex-col items-start"
                    >
                        <div
                            className={"px-5 pt-28 space-y-3 pb-10 w-full"}
                        >
                            <AccountHeader
                                isSaveButton={isSaveButton}
                                sprintMonday={sprintMonday}
                                sprintFriday={sprintFriday}
                                paymentRedLineLocalFormat={paymentRedLineLocalFormat}
                                setOpenPutOnHoldModalState={setOpenPutOnHoldModalState}
                            />
                        </div>

                    </div>
                    <div
                        className={`${displayMode === 'standalone' ? 'pb-20' : 'pb-10 sm:pb-20'} flex flex-col flex-1`}>
                        <div role="tablist" className="tabs tabs-bordered pb-4 px-4">
                            <button
                                role="tab"
                                onClick={() => setTab('profile')}
                                className={`tab ${tab === 'profile' ? 'tab-active' : ''}`}
                            >
                                Profile
                            </button>
                            <button
                                role="tab"
                                onClick={() => setTab('billing')}
                                className={`tab ${tab === 'billing' ? 'tab-active' : ''}`}
                            >
                                Billing
                            </button>
                            <button
                                role="tab"
                                onClick={() => setTab('gifts')}
                                className={`tab ${tab === 'gifts' ? 'tab-active' : ''}`}
                            >
                                Gifts
                            </button>
                        </div>
                        {
                            {
                                'profile': (
                                    <Profile
                                        userUid={userUid}
                                        hasSubscription={hasSubscription}
                                        email={email}
                                        country={country}
                                        currency={currency}
                                        instagram={instagram}
                                        setCountry={setCountry}
                                        setEmail={setEmail}
                                        setCurrency={setCurrency}
                                        setInstagram={setInstagram}
                                        isSaveButton={isSaveButton}
                                        setIsSaveButton={setIsSaveButton}
                                    />
                                ),
                                'billing': (
                                    <History />
                                ),
                                'gifts': (
                                    <GiftCards />
                                ),
                            }[tab]
                        }
                    </div>
                </>
            ) : (
                <Loading isFullScreen={true} />
            )}


            <PutOnHoldModal
                openModal={openPutOnHoldModal}
                setOpenModalState={setOpenPutOnHoldModalState}
                switchOff={!isActiveProfile}
                updateSwitchState={updateSwitchState}
                updateProfileLoading={updateProfileLoading}
                week={currentSprint?.enrolled ? 'next week' : 'this week'}
            />
        </React.Fragment>
    )
}

Account.propTypes = {
    userInfo: PropTypes.object,
    lock: PropTypes.object,
    updateProfileError: PropTypes.object,
    updateProfileLoading: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    updateStudentProfile: PropTypes.func,
    getUserInfo: PropTypes.func,
    currentSprint: PropTypes.object,
    needUpdateBalanceHistory: PropTypes.bool,
    displayMode: PropTypes.string
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    lock: state.user.lock,
    updateProfileLoading: state.user.updateProfileLoading,
    updateProfileError: state.user.updateProfileError,
    currentSprint: state.config.currentSprint,
    needUpdateBalanceHistory: state.billing.needUpdateBalanceHistory
})

const mapDispatchToProps = (dispatch) => ({
    updateStudentProfile: (profile) => dispatch({type: UPDATE_STUDENT_PROFILE, payload: profile}),
})

export default connect(mapStateToProps, mapDispatchToProps)(withUseFormHook(Account))
