import moment from "moment-timezone";

const isValidEmail = email =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    );

export function validateGiftCardCode(code) {
    const regex1 = /^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/;
    const regex2 = /^[A-Z0-9]{5}$/;
    const regex3 = /^UGC-[A-Z0-9]{6}$/;

    return regex1.test(code) || regex2.test(code) || regex3.test(code);
}

export function handleEmailValidation(email) {
    return isValidEmail(email);
}

export function getPaymentRedLine() {

    const today = moment().tz('Australia/Sydney')
    const day = moment().tz('Australia/Sydney')

    let tuesday;

    if(day.isoWeekday() <= 2 ) {
        tuesday = day.isoWeekday(2).set('hour', 10).set('minute', 30)
    } else {
        tuesday = day.add(1, 'weeks').isoWeekday(2).set('hour', 10).set('minute', 30);
    }

    if(today > tuesday) {
        tuesday.add(1, 'weeks')
    }

    return tuesday.utc()

}

export function isSprintDay() {

    const today = moment().tz('Australia/Sydney')
    const monday = moment().tz('Australia/Sydney').isoWeekday(0)
    const friday =  moment().tz('Australia/Sydney').isoWeekday(6)

    return today.isBetween(monday,friday)
}

export function calculatedSum(
    weeks=0,
    isNew=false,
    currency=null,
    priceItems = null,
    offer = null,
    asPresentPrice = false
) {

    let sum;
    let discount;
    let defaultPrice

    if(offer === null) {
        discount = isNew ? priceItems[`${currency}Discount`] : 0
        defaultPrice = priceItems[`${currency}`]
    } else {
        discount = 0
        defaultPrice = asPresentPrice ? offer.presentPrice.unitAmount : offer.price.unitAmount
    }

    sum = weeks * defaultPrice - discount

    return sum
}

export const stringToBoolean = (stringValue) => {
    switch(stringValue?.toLowerCase()?.trim()){
        case "true":
        case "yes":
        case "1":
            return true;

        case "false":
        case "no":
        case "0":
        case null:
        case undefined:
            return false;

        default:
            return JSON.parse(stringValue);
    }
}

export function isAccountSynced(profile) {

    let status

    if(profile) {
        status = !!(profile.instagram && profile.currency && profile.email);
    } else {
        status = null
    }

    return status
}
