import {
  SET_MESSAGE,
  CLEAR_MESSAGE,
  SET_NOTIFICATION_PERMISSIONS,
  SET_NOTIFICATION_LOADING,
  CREATE_FCM_REGISTRATION_REQUEST,
  CREATE_FCM_REGISTRATION_SUCCESS,
  CREATE_FCM_REGISTRATION_ERROR,
  DELETE_FCM_REGISTRATION_REQUEST,
  DELETE_FCM_REGISTRATION_SUCCESS,
  DELETE_FCM_REGISTRATION_ERROR,
  UPDATE_FMC_REGISTRATION_BY_DEVICE_ID_REQUEST,
  UPDATE_FMC_REGISTRATION_BY_DEVICE_ID_SUCCESS,
  UPDATE_FMC_REGISTRATION_BY_DEVICE_ID_ERROR
} from "../actions/message-actions";

const initialState = {
  notificationIsAllowed: false,
  notificationLoading: true,
  creatingFMCRegistration: false,
  deletingFMCRegistration: false,
  updatingFMCRegistration: false
};

export default function messageReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE:
      return { message: payload };

    case CLEAR_MESSAGE:
      return { message: "" };

    case SET_NOTIFICATION_PERMISSIONS:
      return {
        ...state,
        notificationIsAllowed: payload
      }

    case SET_NOTIFICATION_LOADING:
      return {
        ...state,
        notificationLoading: payload
      }

    case CREATE_FCM_REGISTRATION_REQUEST:
      return {
        ...state,
        creatingFMCRegistration: true
      }

    case CREATE_FCM_REGISTRATION_SUCCESS:
      return {
        ...state,
        creatingFMCRegistration: false
      }

    case CREATE_FCM_REGISTRATION_ERROR:
      // TODO: ADD NOTIFICATION ABOUT ERROR
      return {
        ...state,
        creatingFMCRegistration: false
      }

    case DELETE_FCM_REGISTRATION_REQUEST:
      return {
        ...state,
        deletingFMCRegistration: true
      }

    case DELETE_FCM_REGISTRATION_SUCCESS:
      return {
        ...state,
        deletingFMCRegistration: false
      }

    case DELETE_FCM_REGISTRATION_ERROR:
      // TODO: ADD NOTIFICATION ABOUT ERROR
      return {
        ...state,
        deletingFMCRegistration: false
      }

    case UPDATE_FMC_REGISTRATION_BY_DEVICE_ID_REQUEST:
      return {
        ...state,
        updatingFMCRegistration: true
      }

    case UPDATE_FMC_REGISTRATION_BY_DEVICE_ID_SUCCESS:
      return {
        ...state,
        updatingFMCRegistration: false
      }

    case UPDATE_FMC_REGISTRATION_BY_DEVICE_ID_ERROR:
      // TODO: ADD NOTIFICATION ABOUT ERROR
      return {
        ...state,
        updatingFMCRegistration: false
      }

    default:
      return state;
  }
}
