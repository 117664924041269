import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Emoji from "../../components/Emoji";
import pluralize from "pluralize";
// import Pluralize from "react-pluralize";

const ActionTile = ({
                        action,
                        participantType
}) => {

    const [secondString, setSecondString] = useState(<></>)
    const [subTitile, setSubTitle] = useState(<></>)

    useEffect(()=> {
        if(participantType){

            if(participantType === 'participants_only') {
                setSecondString(
                    <span>
                        You will get {action.points} <Emoji className={"inline"} symbol={'🏆'} /> for each {action.type.unit} completed by your friend
                    </span>
                )
                setSubTitle(
                    <span className={"text-sm"}>
                        Requires&nbsp;
                        <span className={"underline decoration-2 decoration-error"}>
                            {`${action.availableCount} ${pluralize(action.type.unit, action.availableCount)}`}
                            {/*<Pluralize*/}
                            {/*    singular={action.type.unit}*/}
                            {/*    count={action.availableCount}*/}
                            {/*    showCount={true}*/}
                            {/*/>*/}
                            {action.inRow && (<>&nbsp;in a row</>)}
                        </span>
                        <>&nbsp;for each friend</>
                    </span>
                )
            } else if (participantType === 'owner_only') {
                setSecondString(
                    <span>
                        {action.availableCount > 1 ? `For each ${action.type.unit} you` : 'You'} will get {action.points} <Emoji className={"inline"} symbol={'🏆'} />
                    </span>
                )
                setSubTitle(
                    <span className={"text-sm"}>
                        Requires&nbsp;
                        {`${action.availableCount} ${pluralize(action.type.unit, action.availableCount)}`}&nbsp;
                        {/*<Pluralize*/}
                        {/*    singular={action.type.unit}*/}
                        {/*    count={action.availableCount}*/}
                        {/*    showCount={true}*/}
                        {/*/>&nbsp;*/}
                        {action.inRow && 'in a row'}
                    </span>
                )
            } else if (participantType === 'participants_and_owner') {
                setSecondString(
                    <span>
                        You will get {action.points} <Emoji className={"inline"} symbol={'🏆'} /> for each {action.type.unit} completed by you or your friend
                    </span>
                )
                setSubTitle(
                    <div className={"text-sm"}>
                        Requires&nbsp;
                        <span className={"underline decoration-2 decoration-error"}>
                            {`${action.availableCount} ${pluralize(action.type.unit, action.availableCount)}`}
                            {/*<Pluralize*/}
                            {/*    singular={action.type.unit}*/}
                            {/*    count={action.availableCount}*/}
                            {/*    showCount={true}*/}
                            {/*/>*/}
                            {action.inRow && (<>&nbsp;in a row</>)}
                        </span>
                        <>&nbsp;for each friend and you</>
                    </div>
                )
            }
        }
    }, [action.availableCount, action.inRow, action.points, action.type.unit, participantType])

    return (
        <React.Fragment>
            <div className={"grid grid-cols-12 w-full"}>
                <div className={"col-span-1"}>
                    <Emoji className={'text-sm'} symbol={'✅'} />
                </div>
                <div className={"col-span-10"}>
                    <div className={"text-base pb-2"}>
                        <span className={"font-bold"}>{action.type.title}.&nbsp;</span>
                        {secondString}
                        {/*{action.availableCount > 1 ? `For each ${action.type.unit} you` : 'You'} will get {action.points}&nbsp;*/}
                    </div>
                    <div className={"prose-sm prose"}>
                        {subTitile}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

ActionTile.propTypes = {
    action: PropTypes.object,
    participantType: PropTypes.string
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ActionTile)