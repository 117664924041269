import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Emoji from "../../components/Emoji";

const ChallengeStats = ({
                            isRepeatable,
                            type,
                            score
}) => {

    return (
        <React.Fragment>
            <div className={"flex flex-row justify-start flex-wrap ms:space-x-6 ms:space-y-0"}>
                <div className={"flex flex-row items-center space-x-2"}>
                    <Emoji symbol={'🏆'} />
                    <span>{score}</span>
                </div>
                <div className={"divider divider-horizontal"}></div>
                <div className={"flex flex-row items-center space-x-2"}>
                    {isRepeatable ? (
                        <>
                            <Emoji symbol={'🔄'}></Emoji>
                            <span>plays</span>
                        </>
                    ) : (
                        <>
                            <Emoji symbol={'1️⃣'}></Emoji>
                            <span>play</span>
                        </>
                    )}
                </div>
                <div className={"divider divider-horizontal"}></div>
                <div className={"flex flex-row items-center space-x-2"}>
                    {type === 'owner_only' && <span className={"text-sm text-primary"}>You</span>}
                    {type === 'participants_only' && <span className={"text-sm text-primary"}>Your friends</span>}
                    {type === 'participants_and_owner' && (
                        <div className={"flex flex-col space-x-0"}>
                            <span className={"text-xs text-primary"}>You</span>
                            <div className={"flex flex-row justify-center items-center space-x-2"}>
                                <span>&</span>
                                <span className={"text-xs text-primary"} >Friends</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}

ChallengeStats.propTypes = {
    isRepeatable: PropTypes.bool,
    type: PropTypes.string,
    score: PropTypes.number
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeStats)