import React, {useEffect, useState} from 'react'

import {connect} from "react-redux";
import PropTypes from "prop-types";
import LegendDropdownIcon from "./LegendDropdownIcon";
import SprintStatCardBody from "./SprintStatCardBody";

const SprintStatCard = ({
                            sprintSummary,
                            dailySummary,
                            today
}) => {

    const [dailyQuestionsDonePercentage, setDailyQuestionsDonePercentage] = useState(0)
    const [dailyCorrectAnswersPercentage, setDailyCorrectAnswersPercentage] = useState(0)

    const [sprintQuestionsDonePercentage, setSprintQuestionsDonePercentage] = useState(0)
    const [sprintCorrectAnswersPercentage, setSprintCorrectAnswersPercentage] = useState(0)

    useEffect(() => {
        if(dailySummary) {
            const allPercentage =  Number((dailySummary.dayAllAssignments / dailySummary.dayQuestions * 100).toFixed(0))
            setDailyQuestionsDonePercentage(isNaN(allPercentage) ? 0 : allPercentage)

            const correctPercentage = Number((dailySummary.dayCorrectAnswers / dailySummary.dayQuestions * 100).toFixed(0))
            setDailyCorrectAnswersPercentage(isNaN(correctPercentage) ? 0 : correctPercentage)
        }
    }, [dailySummary]);

    useEffect(() => {
        if(sprintSummary) {
            const allPercentage =  Number((sprintSummary.allAssignments / sprintSummary.questionsNumber * 100).toFixed(0))
            setSprintQuestionsDonePercentage(isNaN(allPercentage) ? 0 : allPercentage)

            const correctPercentage = Number((sprintSummary.correctAnswer / sprintSummary.questionsNumber * 100).toFixed(0))
            setSprintCorrectAnswersPercentage(isNaN(correctPercentage) ? 0 : correctPercentage)
        }
    }, [sprintSummary]);

    return (
        <div className="carousel carousel-center w-full space-x-4 overflow-y-hidden px-3">
            <div className="carousel-item w-64 min-h-24">
                <div className="w-full relative flex flex-col justify-between bg-dark p-0 rounded-xl px-3 pb-3">
                    <div className={"absolute top-1 right-1"}>
                        <LegendDropdownIcon/>
                    </div>
                    <SprintStatCardBody
                        title={today.isoWeekday() === 6 || today.isoWeekday() === 7 ? "Friday" : "Today"}
                        correct={dailySummary.dayCorrectAnswers}
                        correctPercentage={dailyCorrectAnswersPercentage}
                        donePercentage={dailyQuestionsDonePercentage}
                    />
                </div>
            </div>
            <div className="carousel-item w-64 min-h-24">
                <div className="w-full relative flex flex-col justify-between bg-dark p-0 rounded-xl px-3 pb-3">
                    <div className={"absolute top-1 right-1"}>
                        <LegendDropdownIcon/>
                    </div>
                    <SprintStatCardBody
                        title={"Week"}
                        correct={sprintSummary.correctAnswer}
                        correctPercentage={sprintCorrectAnswersPercentage}
                        donePercentage={sprintQuestionsDonePercentage}
                    />
                </div>
            </div>
        </div>

    )
}

SprintStatCard.propTypes = {
    userInfo: PropTypes.object,
    dailySummary: PropTypes.object,
    sprintSummary: PropTypes.object,
    today: PropTypes.any
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    today: state.common.today
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(SprintStatCard)