import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import moment from "moment/moment";
import {
    DELETE_SUBSCRIPTION,
    DELETE_SUBSCRIPTION_SCHEDULE,
    SET_NEED_UPDATE_BALANCE_HISTORY,
    UPDATE_SUBSCRIPTION
} from "../../redux/actions/billing-actions";
import {usePrevious} from "../../hooks/usePrevious";
import PaymentMethodList from "./PaymentMethodList";

const Subscription = ({
                          userSubscription,
                          updateSubscription,
                          deleteSubscription,
                          updateSubscriptionLoading,
                          deleteSubscriptionLoading,
                          deleteSubscriptionSchedule,
                          userInfo,
                          setNeedUpdateBalanceHistory,
                          priceItems
}) => {


    const [subscriptionStatus, setSubscriptionStatus] = useState('empty')
    const [offerString, setOfferString] = useState('')
    const [renewDate, setRenewDate] = useState(null)
    const [startDate, setStartDate] = useState(null)

    const prevUpdateSubscriptionLoading = usePrevious(updateSubscriptionLoading)

    const subscriptionType = userSubscription.subscription.object
    const product = userSubscription.product
    const [paused, setPaused] = useState(false)
    const plan = userSubscription.plan
    const currency = plan.currency
    const interval = plan.interval
    const amount = plan.amount

    const handleUpdateSubscription = (paused) => {
        const actionType = paused ? 'pause' : 'unpause'
        updateSubscription({
            subscriptionId: userSubscription.subscription.id,
            actionType: actionType,
        })
    }

    const handleDeleteSubscription = () => {

        if(subscriptionType === 'subscription'){
            deleteSubscription({
                subscriptionId: userSubscription.subscription.id,
                weeks: userInfo.balance.weeks
            })
        } else if(subscriptionType === 'subscription_schedule') {
            deleteSubscriptionSchedule({
                subscriptionId: userSubscription.subscription.id,
                weeks: userInfo.balance.weeks
            })
        }
    }

    useEffect(()=>{
        if(userSubscription) {

            const subscription = userSubscription.subscription

            setSubscriptionStatus(subscription.status)

            if(userSubscription.subscription.object === 'subscription_schedule') {
                if(subscription.status === 'not_started') {
                    setStartDate(moment.unix(userSubscription.subscription.phases[0].startDate).format('MMMM D, YYYY'))
                }

            } else {
                setRenewDate(moment.unix(userSubscription.subscription.currentPeriodEnd).add(1, 'days').format('MMMM D, YYYY'))
                if(userSubscription.subscription.pauseCollection?.behavior === 'mark_uncollectible') {
                    setPaused(true)
                    setSubscriptionStatus('paused')
                }
                if(userSubscription.subscription.pauseCollection === null) {
                    setPaused(false)
                }
            }
        }
    }, [userSubscription])

    useEffect(()=>{
        if(updateSubscriptionLoading === false && prevUpdateSubscriptionLoading === true){
            setNeedUpdateBalanceHistory(true)
        }
    }, [prevUpdateSubscriptionLoading, setNeedUpdateBalanceHistory, updateSubscriptionLoading])

    useEffect(()=>{

        // 'completed', 'released' - for subscription schedule only
        // 'incomplete', 'trialing', 'unpaid' - for subscription only

        switch (subscriptionStatus) {
            case 'active' || 'incomplete' || 'trialing' || 'canceled' || 'unpaid' || 'completed' || 'released':
                setSubscriptionStatus(subscriptionStatus)
                break;
            case 'incomplete_expired':
                setSubscriptionStatus('expired')
                break;
            case 'past_due':
                setSubscriptionStatus('past due')
                break;
            case 'not_started':
                setSubscriptionStatus('not started')
        }

    }, [subscriptionStatus])

    useEffect(() => {
        if(priceItems && userInfo){
            const symbol = priceItems[`audSymbol`]
            setOfferString(`${symbol}${amount} ${currency.toUpperCase()}`)
        }
    }, [amount, currency, userInfo, userInfo.profile.currency]);

    return (
        <React.Fragment>
            <div className={"bg-white px-3 shadow-brand-button pt-3"}>
                <div className={"flex flex-row items-center space-x-4"}>
                    <h3 className="font-bold text-lg text-primary">{product.name}</h3>
                    <div className={`badge ${ subscriptionStatus === 'active' ? 'badge-accent' : 'badge-ghost' }`}>
                        {subscriptionStatus}
                    </div>
                </div>
                <div className={"divider p-0 m-0"} />

                <p className="py-4 text-primary">{offerString} per {interval}</p>
                {renewDate && !paused && <p className={"text-primary"}>Your plan renews on {renewDate}</p>}
                {userSubscription.subscription.status === 'not_started' && (
                    <div className={"mb-5"}>
                        <p className={"text-primary mb-2"}>Your plan starts on {startDate}</p>
                        <p className={"text-xs"}>
                            Subscription cycle starts on&nbsp;
                            <span className={"underline decoration-sky-500 decoration-2"}>Saturdays</span>,
                            that’s why your plan will start on&nbsp;
                            <span className={"underline decoration-sky-500 decoration-2"}>{startDate}</span>
                        </p>
                    </div>
                )}

                <div className={"flex flex-col items-center w-full space-y-4 mt-4"}>
                    {userSubscription.subscription.status === 'active' &&
                        <button
                            onClick={()=>handleUpdateSubscription(!paused)}
                            className={`btn btn-md w-40 px-4 rounded-full shadow-brand-button 
                                ${deleteSubscriptionLoading ? 'btn-disabled btn-secondary' : 'btn-primary'}`
                            }
                        >
                            {updateSubscriptionLoading && <span className="loading loading-spinner"></span>}
                            {paused === false ? 'pause' : 'unpause'}
                        </button>
                    }
                    <div
                        onClick={handleDeleteSubscription}
                        className={`btn btn-md w-40 px-4 btn-primary rounded-full shadow-brand-button 
                            ${updateSubscriptionLoading ? 'btn-disabled btn-secondary' : 'btn-secondary'}`
                        }
                    >
                        {deleteSubscriptionLoading && <span className="loading loading-spinner loading-xs"></span> }
                        Cancel
                    </div>
                </div>
                <PaymentMethodList />
            </div>
        </React.Fragment>
    )
}

Subscription.propTypes = {
    userInfo: PropTypes.object,
    userSubscription: PropTypes.object,
    updateSubscriptionLoading: PropTypes.bool,
    deleteSubscriptionLoading: PropTypes.bool,
    updateSubscription: PropTypes.func,
    deleteSubscription: PropTypes.func,
    deleteSubscriptionSchedule: PropTypes.func,
    setNeedUpdateBalanceHistory: PropTypes.func,
    priceItems: PropTypes.object
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    userSubscription: state.billing.userSubscription,
    updateSubscriptionLoading: state.billing.updateSubscriptionLoading,
    deleteSubscriptionLoading: state.billing.deleteSubscriptionLoading,
    priceItems: state.preference.priceItems
})

const mapDispatchToProps = (dispatch) => ({
    updateSubscription: (params) => dispatch({ type: UPDATE_SUBSCRIPTION, payload: params }),
    deleteSubscription: (id) => dispatch({ type: DELETE_SUBSCRIPTION, payload: id }),
    deleteSubscriptionSchedule: (id) => dispatch({ type: DELETE_SUBSCRIPTION_SCHEDULE, payload: id }),
    setNeedUpdateBalanceHistory: (status) => dispatch({ type: SET_NEED_UPDATE_BALANCE_HISTORY, payload: status }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Subscription)
