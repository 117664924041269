import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {START_CHALLENGE} from "../../redux/actions/challenge-actions";
import {usePrevious} from "../../hooks/usePrevious";

const StartChallengeButton = ({
                                  startChallenge,
                                  challengeId,
                                  loadingStartChallenge,
                                  loadingUserInfo,
                                  isLink,
                                  closeDialog,
                                  title
}) => {

    const prevLoadingStatus = usePrevious(loadingStartChallenge || loadingUserInfo);
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        // Ждем когда получим обновленные данные стсатистики пользователя
        // для welcome page
        setLoading(loadingStartChallenge || loadingUserInfo)
    }, [loadingStartChallenge])

    useEffect(()=>{
        if(prevLoadingStatus === true && loading === false){
            closeDialog()
        }
    }, [prevLoadingStatus, loadingStartChallenge, closeDialog])

    return (
        <React.Fragment>
            <button
                onClick={() => startChallenge({challengeId: challengeId})}
                className={`btn break-words ${isLink ? 'px-0 btn-active btn-link text-xs' : 'rounded-full shadow-brand-button btn-primary'}`}
            >
                {loading && <span className="loading loading-spinner"></span>}
                {title}
            </button>
        </React.Fragment>
    )
}

StartChallengeButton.propTypes = {
    title: PropTypes.string,
    startChallenge: PropTypes.func,
    challengeId: PropTypes.string,
    hasReferral: PropTypes.bool,
    loadingStartChallenge: PropTypes.bool,
    loadingUserInfo: PropTypes.bool,
    isLink: PropTypes.bool,
    closeDialog: PropTypes.func
}

const mapStateToProps = (state) => ({
    loadingStartChallenge: state.challenge.loadingStartChallenge,
    loadingUserInfo: state.user.loadingUserInfo
})

const mapDispatchToProps = (dispatch) => ({
    startChallenge: (params) => dispatch({ type: START_CHALLENGE, payload: params }),
})

export default connect(mapStateToProps, mapDispatchToProps)(StartChallengeButton)