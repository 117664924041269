export const SET_MESSAGE = "SET_MESSAGE";
export const SET_MESSAGE_REQUESTED = "export const ";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const CLEAR_MESSAGE_REQUESTED = "CLEAR_MESSAGE_REQUESTED";
export const SET_NOTIFICATION_PERMISSIONS = "SET_NOTIFICATION_PERMISSIONS";
export const SET_NOTIFICATION_LOADING = "SET_NOTIFICATION_LOADING"
export const CREATE_FCM_REGISTRATION = "CREATE_FCM_REGISTRATION"
export const CREATE_FCM_REGISTRATION_REQUEST = "CREATE_FCM_REGISTRATION_REQUEST"
export const CREATE_FCM_REGISTRATION_SUCCESS = "CREATE_FCM_REGISTRATION_SUCCESS"
export const CREATE_FCM_REGISTRATION_ERROR = "CREATE_FCM_REGISTRATION_ERROR"
export const UPDATE_FCM_REGISTRATION = "UPDATE_FCM_REGISTRATION"
export const UPDATE_FCM_REGISTRATION_REQUEST = "UPDATE_FCM_REGISTRATION_REQUEST"
export const UPDATE_FCM_REGISTRATION_SUCCESS = "UPDATE_FCM_REGISTRATION_SUCCESS"
export const UPDATE_FCM_REGISTRATION_ERROR = "UPDATE_FCM_REGISTRATION_ERROR"
export const DELETE_FCM_REGISTRATION = "DELETE_FCM_REGISTRATION"
export const DELETE_FCM_REGISTRATION_REQUEST = "DELETE_FCM_REGISTRATION_REQUEST"
export const DELETE_FCM_REGISTRATION_SUCCESS = "DELETE_FCM_REGISTRATION_SUCCESS"
export const DELETE_FCM_REGISTRATION_ERROR = "DELETE_FCM_REGISTRATION_ERROR"
export const UPDATE_FMC_REGISTRATION_BY_DEVICE_ID = "UPDATE_FMC_REGISTRATION_BY_DEVICE_ID"
export const UPDATE_FMC_REGISTRATION_BY_DEVICE_ID_REQUEST = "UPDATE_FMC_REGISTRATION_BY_DEVICE_ID_REQUEST"
export const UPDATE_FMC_REGISTRATION_BY_DEVICE_ID_SUCCESS = "UPDATE_FMC_REGISTRATION_BY_DEVICE_ID_SUCCESS"
export const UPDATE_FMC_REGISTRATION_BY_DEVICE_ID_ERROR = "UPDATE_FMC_REGISTRATION_BY_DEVICE_ID_ERROR"
