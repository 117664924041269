import {
    put,
    call,
    takeLatest,
} from 'redux-saga/effects'

import {
    CLEAR_MESSAGE,
    CLEAR_MESSAGE_REQUESTED,
    CREATE_FCM_REGISTRATION,
    CREATE_FCM_REGISTRATION_ERROR,
    CREATE_FCM_REGISTRATION_REQUEST,
    CREATE_FCM_REGISTRATION_SUCCESS,
    DELETE_FCM_REGISTRATION,
    DELETE_FCM_REGISTRATION_ERROR,
    DELETE_FCM_REGISTRATION_REQUEST,
    DELETE_FCM_REGISTRATION_SUCCESS,
    SET_MESSAGE,
    SET_MESSAGE_REQUESTED, SET_NOTIFICATION_PERMISSIONS, UPDATE_FCM_REGISTRATION,
    UPDATE_FCM_REGISTRATION_ERROR,
    UPDATE_FCM_REGISTRATION_REQUEST,
    UPDATE_FCM_REGISTRATION_SUCCESS,
    UPDATE_FMC_REGISTRATION_BY_DEVICE_ID,
    UPDATE_FMC_REGISTRATION_BY_DEVICE_ID_ERROR, UPDATE_FMC_REGISTRATION_BY_DEVICE_ID_REQUEST,
    UPDATE_FMC_REGISTRATION_BY_DEVICE_ID_SUCCESS,
} from "../actions/message-actions";

import messageApi from "../api/message-api";

function* clearMessage() {
    yield put({ type: CLEAR_MESSAGE })
}

function* setMessage({ payload }) {
    yield put({ type: SET_MESSAGE, payload: payload })
}

function* createFMCRegistration({ payload }) {
    yield put({ type: CREATE_FCM_REGISTRATION_REQUEST })

    const response = yield call(messageApi.createFMCRegistration, payload)

    if(response && response.status === 200 || response && response.status === 201) {
        yield put({type: CREATE_FCM_REGISTRATION_SUCCESS })
        yield put({ type: SET_NOTIFICATION_PERMISSIONS, payload: true })
    } else {
        yield put({ type: CREATE_FCM_REGISTRATION_ERROR, payload: response.status })
        yield put({ type: SET_NOTIFICATION_PERMISSIONS, payload: false })
    }
}

function* updateFMCRegistration({ payload }) {
    yield put({ type: UPDATE_FCM_REGISTRATION_REQUEST })

    const response = yield call(messageApi.updateFMCRegistration, payload)

    if(response && response.status === 200) {
        yield put({type: UPDATE_FCM_REGISTRATION_SUCCESS })
        yield put({ type: SET_NOTIFICATION_PERMISSIONS, payload: true })
    } else {
        yield put({ type: UPDATE_FCM_REGISTRATION_ERROR, payload: response.status })
    }
}

function* deleteFMCRegistration({ payload }) {
    yield put({ type: DELETE_FCM_REGISTRATION_REQUEST })

    const response = yield call(messageApi.deleteFMCRegistration, payload)

    if(response && response.status === 204) {
        yield put({type: DELETE_FCM_REGISTRATION_SUCCESS })
        yield put({ type: SET_NOTIFICATION_PERMISSIONS, payload: false })
    } else {
        yield put({ type: DELETE_FCM_REGISTRATION_ERROR })
    }
}

function* updateFMCRegistrationByDeviceId({ payload }) {
    yield put({ type: UPDATE_FMC_REGISTRATION_BY_DEVICE_ID_REQUEST })

    const response = yield call(messageApi.updateFMCRegistrationByDeviceId, payload)

    if(response && response.status === 200) {
        yield put({ type: UPDATE_FMC_REGISTRATION_BY_DEVICE_ID_SUCCESS })
        yield put({ type: SET_NOTIFICATION_PERMISSIONS, payload: true })
    } else if(response && response.status === 404) {
        yield put({ type: UPDATE_FMC_REGISTRATION_BY_DEVICE_ID_SUCCESS })
        yield put({ type: CREATE_FCM_REGISTRATION, payload: payload })
    } else {
        yield put({ type: UPDATE_FMC_REGISTRATION_BY_DEVICE_ID_ERROR })
    }
}

export default function* messageSaga() {
    yield takeLatest(CLEAR_MESSAGE_REQUESTED, clearMessage)
    yield takeLatest(SET_MESSAGE_REQUESTED, setMessage)
    yield takeLatest(CREATE_FCM_REGISTRATION, createFMCRegistration)
    yield takeLatest(UPDATE_FCM_REGISTRATION, updateFMCRegistration)
    yield takeLatest(DELETE_FCM_REGISTRATION, deleteFMCRegistration)
    yield takeLatest(UPDATE_FMC_REGISTRATION_BY_DEVICE_ID, updateFMCRegistrationByDeviceId)
}
