import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Emoji from "../../components/Emoji";

const OwnedChallengeTitle = ({ userChallenge, userInfo, isModal= true }) => {

    return (
        <React.Fragment>
            <div className={`${isModal ? 'w-10/12': 'w-full' } flex flex-row flex-wrap justify-start items-center pb-1`}>
                <h2 className="card-title">{userChallenge.challenge.title}</h2>&nbsp;
                <span className={`inline badge ${userChallenge.isCompleted ? 'badge-primary' : 'badge-accent'}`}>
                    {userChallenge.isCompleted ? 'Finished' : 'In progress'}
                </span>
            </div>
            <div className={"flex flex-row justify-start space-x-6"}>
                <div className={"flex flex-row items-center space-x-2"}>
                    <span>{userChallenge.ownerRewards.length}</span>
                    <div className={"text-lg"}><Emoji symbol={'🎯'} /></div>
                </div>
                <div className={"flex flex-row items-center space-x-2"}>
                    <span>{userChallenge.participants.length}</span>
                    <div className={"text-lg"}><Emoji symbol={'👥'} /></div>
                </div>
                <div className={"flex flex-row items-center space-x-2"}>
                    <span>{userChallenge.balances.find(balance => balance.user === userInfo.uid && balance.isOwner).points}</span>
                    <div className={"text-lg"}><Emoji symbol={'🏆'} /></div>
                </div>
            </div>
        </React.Fragment>
    )
}

OwnedChallengeTitle.propTypes = {
    isModal: PropTypes.bool,
    userChallenge: PropTypes.object,
    userInfo: PropTypes.object
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(OwnedChallengeTitle)