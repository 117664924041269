import React, {useEffect, useRef, useState} from "react";
import PropTypes, {any} from "prop-types";
import {connect} from "react-redux";
import {
    HIDE_STORIES,
} from "../../../redux/actions/stories-actions";
import {IoMdClose} from "react-icons/io";
import {IconContext} from "react-icons/lib";
import {Parser} from "html-to-react";
// eslint-disable-next-line no-unused-vars
import {HTMLToJSON} from "html-to-json-parser";
import Handlebars from "handlebars";

const Story = ({
                   content,
                   userInfo,
                   hideStories,
                   settings,
                   // eslint-disable-next-line no-unused-vars
                   currentSprint,
                   nearestEnrollmentSprintDate
}) => {

    // eslint-disable-next-line no-unused-vars
    const htmlParser = new Parser();
    const [storyClass, setStoryClass] = useState('bg-mint')
    const [storyStyle, setStoryStyle] = useState({})
    // eslint-disable-next-line no-unused-vars
    const contentRef = useRef()

    const handleHideStories = () => {
        hideStories()
    }

    useEffect(()=>{

        if(settings) {
            const keys = Object.keys(settings)
            keys.forEach(key=> {
                if(key==='class') {
                    setStoryClass(settings[key])
                } else if (key==='style') {
                    setStoryStyle(settings[key])
                }
            })
        }
    },[settings])

    useEffect( () => {
        async function convertToJson(element){
            return await HTMLToJSON(element, true);
        }

        if (contentRef.current && process.env.NODE_ENV === 'development') {
            const element = contentRef.current.innerHTML
            if(element){
                let result = convertToJson(element)
                result.then(result => console.log(result))
            }
        }
    }, [contentRef])

    useEffect(()=> {

        if(content && userInfo && currentSprint){
            const template = Handlebars.compile(`${content}`)

            const templateVariables = {
                nearestEnrollmentSprintDate: nearestEnrollmentSprintDate,
                formattedNearestEnrollmentDate: nearestEnrollmentSprintDate.format('MMMM, D Y'),
                currentSprint: currentSprint,
                userInfo: userInfo
            }

            contentRef.current.innerHTML = template(templateVariables)
        }
    }, [content])


    return (
        <div className={`flex flex-col w-full h-full justify-star pt-5 ${storyClass}`} style={storyStyle}>
            {/*HEADER*/}
            <div
                className={"flex flex-row w-full justify-end pr-2.5 cursor-pointer"}
                style={{ zIndex: 1000 }}
                onClick={handleHideStories}
            >
                <IconContext.Provider value={{className: "w-6 h-6"}}>
                    <IoMdClose />
                </IconContext.Provider>
            </div>
            {/*BODY*/}
            <div ref={contentRef} className={"h-full w-full pt-2 px-3"}>
            </div>
        </div>
    );
};

Story.propTypes = {
    userInfo: PropTypes.object,
    currentSprint: PropTypes.object,
    content: PropTypes.any,
    toggleMore: PropTypes.any,
    action: PropTypes.any,
    hideStories: PropTypes.func,
    previewStory: PropTypes.func,
    nextStory: PropTypes.func,
    resetToInitStory: PropTypes.func,
    settings: PropTypes.object,
    contentRef: PropTypes.any,
    nearestEnrollmentSprintDate: any
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    currentSprint: state.config.currentSprint,
    nearestEnrollmentSprintDate: state.common.nearestEnrollmentSprintDate
})

const mapDispatchToProps = (dispatch) => ({
    hideStories: () => dispatch({type: HIDE_STORIES}),
})

export default connect(mapStateToProps, mapDispatchToProps)(Story)