import {accountApiUrl} from "../../helpers/constants";
import camelcaseKeys from "camelcase-keys";
import client from "./client";
import snakecaseKeys from "snakecase-keys";

const accountClient = client(`${accountApiUrl}/api/v1/notification`)

const createFMCRegistration = async (payload) => {
    try {
        const data = snakecaseKeys(payload)
        const response = await accountClient.post(`/registration/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const updateFMCRegistration = async (payload) => {
    try {
        const data = snakecaseKeys(payload)
        const response = await accountClient.put(`/registration/${payload.registrationId}`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const deleteFMCRegistration = async (payload) => {
    try {
        const response = await accountClient.delete(`/registration/${payload}`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const updateFMCRegistrationByDeviceId = async (payload) => {
    try {
        const data = snakecaseKeys(payload)
        const response = await accountClient.put(`/device/${payload.deviceId}`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const messageApi = {
    createFMCRegistration: createFMCRegistration,
    updateFMCRegistration: updateFMCRegistration,
    deleteFMCRegistration: deleteFMCRegistration,
    updateFMCRegistrationByDeviceId: updateFMCRegistrationByDeviceId
}

export default messageApi
